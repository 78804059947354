export * from "./boardActions";
export * from "./ListActions";
export * from "./CardActions";

export const CONSTANTS = {
  ADD_CARD: "ADD_CARD",
  ADD_LIST: "ADD_LIST",
  DRAG_HAPPENED: "DRAG_HAPPENED",
  EDIT_CARD: "EDIT_CARD",
  DELETE_CARD: "DELETE_CARD",
  EDIT_LIST_TITLE: "EDIT_LIST_TITLE",
  DELETE_LIST: "DELETE_LIST",
  ADD_BOARD: "ADD_BOARD",
  DELETE_BOARD: "DELETE_BOARD",
  SET_CURRENT_BOARD: "SET_CURRENT_BOARD",
};
